$duration-in: 0.5s;
$duration-out: 0.25s;

// Key Frames
@keyframes slideInRight {
  0% {
    transform: translate3d(105%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {

  0% {

    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  to {

    transform: translate3d(105%, 0, 0);
    -webkit-transform: translate3d(105%, 0, 0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-105%, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(-105%, 0, 0);
  }

}



// CSS Classes

.basic-animation {
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.76, 0.08, 0.71, 0.95);
  &-in {
    animation-duration: $duration-in;
  }

  &-out {
    animation-duration: $duration-out;
  }
}

.slide {
  &-right {
    &-in {
      @extend .basic-animation-in;
      animation-name: slideInRight;
    }

    &-out {
      @extend .basic-animation-out;
      animation-name: slideOutRight;
    }
  }

  &-left {
    &-in {
      @extend .basic-animation-in;
      animation-name: slideInLeft;
    }

    &-out {
      @extend .basic-animation-out;
      animation-name: slideOutLeft;
    }
  }
}
