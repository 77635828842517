@import "variables";


.secondary-btn-v2-1 {
  font-size: .855em;
}

.marketplace-structured-products-grid-container {
  height: calc(100vh - 20rem);
  width: calc(100% - 1rem);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.pm-responsive-text {
    @include responsive-font-size($responsive-font-sizes);
    font-family: 'Montserrat';
}

.icon-section {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat";
  gap: 1em;
  justify-content: flex-start;
  margin: 1em;
}

.sort-and-pills {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat";
  column-gap: 2em;
  row-gap: .5em;
  justify-content: flex-start;
  flex-shrink: 1;
  margin: 0 1em;
}

.product-marketplace {
  @include responsive-font-size($responsive-font-sizes);
  font-family: 'Montserrat';
  margin: 16px 24px;
  &-views {
    align-content: center;
    display: flex;
    flex-direction: row;
    & > &-title {
      color: #7f929e;
      cursor: pointer;
      font-size: 1.2em;

      font-weight: 500;
      padding-right: 8px;
      position: relative;

      &.selected-view {
        color: #00263e;
        pointer-events: none;
      }

      &.disabled-views {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:not(:first-child) {
        padding-left: 8px;
        &:before {
          background: #7f929e;
          border-radius: 2px;
          content: "";
          height: 75%;
          left: 0;
          opacity: 1;
          position: absolute;
          top: 12.5%;
          width: 2px;
        }
      }
    }
  }

  &-header {
    color: #00263e;
    display: flex;
    justify-content: space-between;
    &-title {
      font-size: 2.4em;
      font-weight: 900;
    }
    & .icon-row {
      .secondary-btn-v2 {
        height: 2em;
        margin-left: 12px;
        border-radius: 8px;
        .btn-text {
          font-weight: 500;
          margin-left: 6px;
        }
      }
    }
  }


  .button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    gap: .6em;
  }
}

.background-green {
  background-color: #e5f2b5;
}

.marketplace-icon {
  &.secondary-btn-v2 {
    font-weight: 500;
    height: 2.7em;
    min-width: 2.7em;
    padding: 0;
  }
}

.spinner-icon-button {
  &.mat-progress-spinner {
    display: unset;
    left: calc(50% - 12px);
    overflow: hidden;
    position: absolute;
    width: 100%;
  }
}

.transparent-btn-text {
  &.secondary-btn-v2:disabled,
  &, &[disable] {
    color: transparent;
  }
}

.visible-count {
  min-width: max-content;
  width: 8.5em;
  font-size: .855em;
  font-weight: 900;
  padding-left: 1.4em;
  align-self: flex-start;
}


.active-grid-sort-and-filters {
  display: flex;
  flex-flow: row wrap-reverse;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.sort-and-chips-container {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
}
