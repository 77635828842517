@import "variables";

/*
 This is a temporary fix for:

.mat-form-field-wrapper {
  margin-bottom: -11px !important;
}
*/
%mat-form-field-wrapper-fix {
  .mat-form-field-wrapper {
    margin-bottom: 0.25em !important;
  }
}


.mat-form-field-fix {
  @extend %mat-form-field-wrapper-fix;
}

/* Mat Toggle Button */

.luma-mat-toggle-button {
  border: none;

  .mat-button-toggle-group {
    margin-top: 0.2em;
  }


  .mat-button-toggle-appearance-standard {
    color: $disabled-text;
  }

  .mat-button-toggle-checked {
    background: $luma-brand-bright-green;
    color: $text-grey;
  }

  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background: rgba($luma-brand-bright-green, 0.5);
  }

  @mixin toggle-group-content {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        @content
      }
    }
  }

  @include toggle-group-content {
    font-family: 'Montserrat';
    line-height: 40px;
    min-width: fit-content;
    padding: 0 $spacing-xl;
  }

  &.toggle-font-12 {
    @include toggle-group-content {
      font-size: 12px;
    }
  }

  &.fixed-width-40 {
    @include toggle-group-content {
      width: 40px;
      padding: 0;
    }
  }

  &.fixed-width-96 {
    @include toggle-group-content {
      width: 96px;
      padding: 0;
    }
  }

  &.toggle-button-padding {
    @include toggle-group-content {
      padding: $spacing-xs $spacing-xl;
    }
  }

  &-small-text {
    @extend .luma-mat-toggle-button;
    font-size: 14px;
  }
}

.luma-mat-toggle-button-flex {
  @extend .luma-mat-toggle-button;
  .mat-button-toggle-appearance-standard {
    flex: 1;
    & {
      .mat-button-toggle-label-content {
        padding: 0;
      }
    }
  }
}

/* End of Mat Toggle Button */


/* Mat Progress Bar */
.luma-mat-progress-bar {
  .mat-progress-bar-buffer {
    background: #d8d8d8;
  }

  .mat-progress-bar-fill::after {
    background-color: $medium-grey ;
  }
}

.luma-mat-progress-bar-warning {
  .mat-progress-bar-buffer {
    background: #d8d8d8;
  }

  .mat-progress-bar-fill::after {
    background-color: $error-red ;
  }
}

/*  End Of Mat Progress Bar */


/* Mat form Field */
.luma-mat-form-gray-outline {
  .mat-form-field-appearance-outline .mat-form-field-outline,
  mat-label :not(.ng-invalid),
  .mat-focused .mat-form-field-label
  {
    color: $luma-medium-gray;
  }
}

.luma-mat-form-outline-darker {
  @extend .luma-mat-form-gray-outline;

  .mat-form-field-disabled,
  .toggle-disabled {
    opacity: 0.3;
  }
}

/* End of Mat Form Field */

/* Mat Progress Spinner */
.luma-spinner {
  &.mat-progress-spinner circle,
  &.mat-spinner circle {
    stroke: $cta-primary-blue;
  }
}
/* End of Mat Form Field */

/* Mat Slide Toggle */
// Unchecked
.luma-slide-toggle {
  &.mat-slide-toggle .mat-slide-toggle-bar {
    height: 10px;
    width: 26px;
  }

  &.mat-slide-toggle .mat-slide-toggle-thumb {
    height: 16px;
    width: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }

  // Unchecked
  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $highlight-green;
  }
  &.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $input-field-grey;
  }
}

/* Mat Slide Toggle */
app-issuer-confirmation-creation {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $cta-primary-blue;
  }

  .mat-form-field {
    font-size: 12px;
    font-family: $font-family;
    color: $text-grey;

    .mat-form-field-label-wrapper {
      height: 40px;
      top: -1.1rem;
    }

    .mat-form-field-suffix {
      top: -0.1rem;
    }

    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(.75);
      width: 133.33333%;
    }
  }
}

.mat-snack-bar-container.top-right-snackbar {
  position: absolute;
  top: 35px;
  right: 25px;
}

/* Mat Menu */
.luma-split-button-menu {
  background: $text-grey;

  .mat-menu-item {
    font-family: "Montserrat";
    color: white;
    text-align: center;
    line-height: unset;
    height: unset;
    padding: 12px;

    &.selected-mat-menu-item {
      background: $text-grey;
      &:focus {
        background: $text-grey;
      }
    }

    &:hover:not([disabled]),
    &:hover {
      // TODO after 09-25 release
      background: #868686;
    }
  }

  // TODO - This may impact accessibility
  button.mat-menu-item:focus {
    background: transparent;
  }

  &.mat-menu-panel.under {
    transform-origin: top center !important;
  }

  &.mat-menu-panel.above {
    transform-origin: bottom center !important;
  }

  &.mat-menu-panel {
    padding: 0;
    max-width: 100%;
    min-width: 50px;
    margin: 0 8px;
    background: $medium-grey;

    & .mat-menu-content:not(:empty) {
      padding: 2px 0;
    }

    &.below {
      transform-origin: top center !important;
      border-radius: 0 0 4px 4px;

    }

    &.above {
      transform-origin: bottom center !important;
      border-radius: 4px 4px 0 0;
    }
  }
}
/* End of Mat Menu */

/* Mat Expansion Panel */

.luma-mat-panel {
  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-family: 'Montserrat';
  }

  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-content {
    line-height: normal;
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.mat-expansion-panel {
    margin: 24px 0;
    @extend .luma-box-shadow;
  }

  &.mat-expansion-panel {
    @mixin left-side-padding($left-side-color) {
      &:not(.no-panel-hover) .mat-expansion-panel-header,
      & .mat-expansion-panel-header .luma-active-panel, {
        &:before {
          content: "";
          width: 24px;
          height: 100%;
          background: $left-side-color;
        }
      }
    }

    &.mat-expanded {
      @include left-side-padding($luma-brand-bright-green);
    }

    &:not(.mat-expanded) {
      @include left-side-padding($disabled-grey);
    }

    & > .mat-expanded {
      background: $info-grey;
      height: 48px;
    }
  }

  &.no-panel-hover {
    & > .mat-expansion-panel-header > .mat-content {
      height: 100%;
    }

    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover,
    &.mat-expansion-panel-header.mat-expanded:focus, &.mat-expansion-panel-header.mat-expanded:hover,
    &.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
    &.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
    &.mat-expansion-panel.mat-expanded .mat-expansion-panel-header:hover:not([aria-disabled=true]), {
      background: $info-grey;
      cursor: default;
    }

    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
      background: inherit;
    }
  }
}

/* End of Mat Expansion Panel*/


/* Start of Snackbar */
.no-padding-snackbar {
  &.mat-snack-bar-container {
    padding: 0;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    background: white;
  }
}
/* End of Snackbar */

/* Start of Tooltip */
// We can extend luma-box-shadow if every tooltip should appear that way.
.luma-tooltip {
  &.mat-tooltip {
    font-family: 'Montserrat';
    background-color: $white;
    color: $medium-grey;
    font-size: 0.75em;
    font-style: oblique;
    max-width: 400px;
    margin: 4px;

    // When arrows will be added
    overflow: visible;
    position: relative;
  }
}
/* End of Tooltip */


/* Start of Mat Select */
.luma-full-width-select-option {
  &.mat-select-panel {
    max-width: unset;
  }
}
/* End of Mat Select */

.multiline-tooltip {
  white-space: pre-wrap;
}

mat-card.card-placeholder {
  text-align: center;
  padding: 15em 0;
  max-width: 86em;
  margin: 2em auto 10em auto;
  @media only screen and (max-width: 84em) {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.mat-button-spinner-replacement {
  margin:4px 0;
}


/* Start of Scuffed Overrides */
.mat-select-filter {
  &.mat-select {
    font-family: $font-family;
    width: 100%;
    max-width: max(16.6em, 100%);
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
    outline: 1px solid rgb(0 0 0 / 10%);
    height: 2.1em;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    &.active-filter {
      background-color: $active-green;
    }

    .mat-select-trigger .mat-select-value,
    .mat-select-placeholder {
      color: #7f929e;
      padding-right: 4px;
      .trigger-value {
        color: #00263e;
      }
    }

    .mat-select-arrow {
      color: #00263e;
    }

    & .mat-select-trigger {
      display: flex;
      align-items: center;
      padding: 0 12px;
      flex: 1;
      font-size: 0.855em;
    }

    & .mat-select-value {
      min-width: unset;
      max-width: unset;
      & .mat-select-value-text {
        padding-right: 4px;
      }
    }
  }

  &.mat-select {
    &.mat-select-invalid  {
      outline: 1px solid $error-red;
      .mat-select-placeholder {
        color: $error-red;
      }
    }
  }
}

.no-active {
  &.mat-select-panel .mat-option {
    height: 2.25em;
    line-height: 2.25em;
    font-size: 1em;
  }
  .mat-option.mat-active {
    background-color: transparent;
  }
  .mat-pseudo-checkbox-checked {
    background-color: $cta-primary-blue;
  }
}

.select-all {
  width: 100%;
  .mat-checkbox-layout {
    &,
    & .mat-checkbox-label {
      width: 100%;
    }
  }
}

.mat-checkbox-indeterminate.mat-accent.select-all,
.mat-checkbox-checked.mat-accent.select-all {
  & .mat-checkbox-background {
    background-color: $cta-primary-blue;
  }
}

.mat-select-filter.mat-select .mat-select-trigger .mat-select-value {
  color: #00263e;
}


.custom-tabs {
  .mat-tab-labels .mat-tab-label {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-sizing: content-box;
    color: #7f929e;
    line-height: 26px;
    margin-right: 24px;
    min-width: 0 !important;
    padding: 0;

    .mat-tab-label-content {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600;
    }

    &.mat-tab-label-active {
      border-bottom: 4px solid $highlight-green;
      color: #00263e;
      &:not(.mat-tab-disabled) {
        border-left: none;
        border-right: none;
        border-top: none;
        opacity: 1;
      }
    }

    &:not(.mat-tab-label-active) {
      opacity: .75;

      &:hover {
        color: $button-hover;
        opacity: 1;
      }
    }

    &.mat-tab-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .mat-ink-bar {
    display: none;
  }
  .mat-button-spinner-replacement {
    margin:4px 0;
  }
}
/* End of Scuffed Overrides */

.filter-overlay-margins {
  margin: 0 2em;
}
